import React from 'react';
import cx from 'classnames';
import styles from './Button.module.css';
import { BasicRectangleButtonLinkProperties, HoldingButtonProperties } from './ButtonInterfaces';

import Link from 'next/link';

export const BasicRectangleButtonLink = ({
  children,
  reverse = false,
  theme,
  Icon,
  ...rest
}: BasicRectangleButtonLinkProperties) => (
  <Link className={getClassNames(theme, reverse)} {...rest}>
    <span>{children}</span>
    {Icon ? <Icon /> : undefined}
  </Link>
);

const getClassNames = (theme: HoldingButtonProperties['theme'], reverse: boolean) =>
  cx(styles.sharedStyles, styles.multiRow, styles.backLinkButton, styles.basicRectangle, {
    [styles.reverse]: reverse,
  });
