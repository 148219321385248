import { Arrow } from '@klokgroep/shared-components/src/Icons';
import { BasicRectangleButtonLink } from '@klokgroep/shared-components/src/Button/BasicRectangleButtonLink';
import { MaxWidth } from '@klokgroep/shared-components/src/MaxWidth';
import { PageHeroProperties } from './PageHeroInterfaces';
import { PortableTextWithLinks } from '@klokgroep/shared-components/src/PortableTextWithLinks';
import { RichText } from '@klokgroep/shared-components/src/RichText';
import { TitleOverTwoRules } from '@klokgroep/shared-components/src/TitleOverTwoRules';
import { useSiteInfo } from '@klokgroep/shared-components/src/SiteInfoProvider';
import cx from 'classnames';
import styles from './PageHero.module.css';
import { HeroCarousel, getHeroSizes } from '@klokgroep/shared-components/src/PageHero';
import { ButtonLink } from '@klokgroep/shared-components/src/Button';
import { createHref } from '@klokgroep/sanity';
import { useMemo } from 'react';

// ------------------------------------------------------------
// Fullwidth pagehero (for use in Heilijgers).
// This is a copy of the Corporate.Boxed.PageHero.tsx, but with the following changes:
// - No highlighted content block
// - No MaxWidth component wrapped around the header.
// - Array of sizes also support large screens.
// ------------------------------------------------------------

export const PageHero = ({
  title = '',
  richText,
  mediaItems,
  button,
  carouselAutoplay = false,
  backLink,
  contentMaxWidthSize,
  children,
  ratio = { desktop: 16 / 7, mobile: 1 },
  heroContent,
}: PageHeroProperties) => {
  const { theme, locale } = useSiteInfo();
  const backLinkLabel = useMemo(
    () => (backLink?.label && backLink?.label?.length > 80 ? `${backLink?.label?.slice(0, 80)}...` : backLink?.label),
    [backLink]
  );
  const hasMediaItems = useMemo(
    () => mediaItems && mediaItems.length > 0 && (mediaItems[0]._type == 'image' ? !!mediaItems[0].asset : true),
    [mediaItems]
  );
  return (
    <header className={cx(styles.container, styles.hasPadding, styles[theme])}>
      {hasMediaItems ? (
        <div
          className={cx(styles.heroWrapper, styles.fullWidth)}
          style={{ '--ratio-desktop': ratio.desktop, '--ratio-mobile': ratio.mobile } as React.CSSProperties}>
          <div className={cx(styles.heroCarouselHolder, styles.hasNoIndicators)}>
            <HeroCarousel items={mediaItems} sizes={getHeroSizes(ratio)} carouselAutoplay={carouselAutoplay} />
          </div>

          {heroContent ? (
            <div className={styles.heroRelativeContent}>
              <MaxWidth noMarginOverflow>{heroContent}</MaxWidth>
            </div>
          ) : undefined}

          {backLink?.href ? (
            <div className={cx(styles.heroButtonContainer, styles.hasNoSpacing)}>
              <MaxWidth>
                <BasicRectangleButtonLink href={backLink.href} Icon={Arrow} reverse>
                  {backLinkLabel}
                </BasicRectangleButtonLink>
              </MaxWidth>
            </div>
          ) : undefined}
        </div>
      ) : undefined}
      {title || richText || children || (button && button?.label) ? (
        <MaxWidth size={contentMaxWidthSize || undefined}>
          {hasMediaItems ? undefined : backLink?.href ? (
            <BasicRectangleButtonLink href={backLink.href} Icon={Arrow} reverse>
              {backLinkLabel}
            </BasicRectangleButtonLink>
          ) : undefined}
          <div className={styles.contentGrid}>
            <section
              className={cx(
                styles.relativeContent,
                contentMaxWidthSize === 'small' || contentMaxWidthSize === 'medium' ? styles.fullWidth : undefined
              )}>
              <RichText>
                {title ? <TitleOverTwoRules as="h1">{title}</TitleOverTwoRules> : undefined}
                {richText ? <PortableTextWithLinks content={richText} /> : undefined}
                {button && button?.label ? (
                  <ButtonLink
                    theme={theme === 'vandeklok' ? 'orange' : undefined}
                    target={button?.targetBlank ? 'blank' : undefined}
                    href={createHref({ ...button, locale })}>
                    {button?.label}
                  </ButtonLink>
                ) : undefined}
              </RichText>
              {children}
            </section>
          </div>
        </MaxWidth>
      ) : undefined}
    </header>
  );
};
